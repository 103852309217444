import React, {useState} from 'react';
import {Button, Card, Modal} from "semantic-ui-react";
import {getWaybillById} from "../../../api/waybills";

const CompleteWithWinnerBtn = ({children, onClick: onComplete, wb, disabled, as, ...props}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const onClick = async () => {
        const newWbs = await getWaybillById(wb.waybillId);
        const newWb = (newWbs || []).length ? newWbs[0] : {};

        if ((newWb.auction || {}).bestBet !== wb.bestBet) {
            setModalOpen(true);
        } else {
            onComplete && onComplete();
        }
    };

    return (
        <>
            <Card
                {...props}
                className='without-styles'
                as={as}
                disabled={disabled}
                onClick={onClick}
            >
                {children}
            </Card>
            <Modal
                size='small'
                open={modalOpen}
            >
                <Modal.Header>
                    Изменения по лучшему предложению
                </Modal.Header>
                <Modal.Content>
                    В аукционе произошли изменения по лучшему предложению. Обновите, пожалуйста, страницу
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='green'
                        onClick={() => setModalOpen(false)}
                    >
                        Ок
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default CompleteWithWinnerBtn;